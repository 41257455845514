import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutBanner from "../components/AboutBanner"

function Membership() {
  const subscribe = async (e: any) => {
    e.preventDefault()

    const form = e.currentTarget
    const formData = new FormData(form)
    const keyValuePairs: string[] = []

    formData.forEach((value, key) => {
      keyValuePairs.push(`${key}=${value}`)
    })
    const formDataString = keyValuePairs.join("&")
    console.log("formDataString :", formDataString)
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxLPVOwm7hdE18ZJGr747PeCcZTsuG3QE6CC0KQY1Y/dev",
        {
          redirect: "follow",
          method: "POST",
          body: formDataString,
          mode: "no-cors",
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )

      console.log("response :", response)
      if (response.ok) {
        const data = await response.json()
        console.log("data :", data) // Display a success message
        const messageElement = document.getElementById(
          "message"
        ) as HTMLDivElement

        if (messageElement) {
          messageElement.textContent = "Data submitted successfully!"
          messageElement.style.display = "block"
          messageElement.style.backgroundColor = "green"
          messageElement.style.color = "beige"
        }
        const submitButton = document.getElementById(
          "submit-button"
        ) as HTMLButtonElement

        if (submitButton) {
          submitButton.disabled = false
        }

        form.reset()
        setTimeout(() => {
          if (messageElement) {
            messageElement.textContent = ""
            messageElement.style.display = "none"
          }
        }, 2600)
      } else {
        throw new Error("Failed to submit the form.")
      }
    } catch (error) {
      // Handle errors, you can display an error message here
      console.error(error)
      const messageElement = document.getElementById(
        "message"
      ) as HTMLDivElement
      if (messageElement) {
        messageElement.textContent =
          "An error occurred while submitting the form."
        messageElement.style.display = "block"
      }
    }
  }

  return (
    <Layout headerElement={<AboutBanner />} scrollStepInPx={50} delayInMs={2}>
      <SEO title="Become a Member" />
      <section
        className="background-black"
        style={{ marginBottom: 200, marginTop: 100 }}
      >
        <img
          className="img--layout"
          src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/spiral.png"
          alt="img"
        />
        <div className="container p-0">
          <div className="forum-search-form p-b-100">
            <div className="row">
              <div className="col-lg-10 center m-b-40">
                <div className="text-center">
                  <h2 className="text-medium dpad-text-color mt-5">
                    Become a member!
                  </h2>
                  <p className="p-b-0 text-white" style={{ fontSize: 18 }}>
                    The ANSDPAD Coalition is an open group, for people of
                    African Descent, representing organizations that serve
                    African Nova Scotian people, communities, interests and
                    needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="img-box">
            <div className="forum">
              <div className="logo-dark w-100 text-center mt-4 mb-4">
                <img
                  src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/dpad-logo.png"
                  width="250"
                />
              </div>
              <div className="logo-dark w-100 text-center mt-5 mb-4">
                <h3>Membership Form</h3>
                <hr
                  style={{
                    borderTop: "1px solid rgba(0,0,0,.9)",
                    paddingBottom: 40,
                  }}
                />
              </div>
              <div className="row m-b-40">
                <form
                  id="membership-form"
                  name="membership-form"
                  method="POST"
                  onSubmit={subscribe}
                  className="w-100"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="membership-form"
                  />
                  <div className="form-group row">
                    <div className="form-group col-sm-6">
                      <label className="col-form-label col-sm-12">Name</label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          name="Name"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <label className="col-form-label col-sm-12">
                        Organization Name (If applicable){" "}
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          name="Organization_Name"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="form-group col-sm-6">
                      <label className="col-form-label col-sm-12">
                        Address
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          name="Address"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <label className="col-form-label col-sm-12">Title</label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          name="Title"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="form-group col-sm-6">
                      <label className="col-form-label col-sm-12">
                        Website
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          name="Website"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <label className="col-form-label col-sm-12">Phone</label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          name="Phone"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="form-group col-sm-6">
                      <label className="col-form-label col-sm-12">Email</label>
                      <div className="col-sm-12">
                        <input
                          type="email"
                          name="Email"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <label className="col-form-label col-sm-12">
                        Community
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          name="Community"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className="col-form-label col-sm-12 ml-3"
                      style={{ fontWeight: 600 }}
                    >
                      Organization Information - Please provide a brief
                      description about your organization (mission statement,
                      key area of focus)
                    </label>
                    <div className="form-group col-sm-12">
                      <textarea
                        className="form-control ml-3 mr-3 w-75"
                        name="Organization_Information"
                        rows={3}
                      ></textarea>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className="col-form-label col-sm-12 ml-3"
                      style={{ fontWeight: 600 }}
                    >
                      Areas of Focus - Please indicate the areas that you or
                      your organization aligns with:
                    </label>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Arts"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">
                          Arts and Culture
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Economics"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">
                          Economics (development, entrepreneurship, and
                          sustainable employment inclusive of communication,
                          technology and innovation)
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Education"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">
                          Education
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Enviromental_Racism"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">
                          Environmental Racism
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Health"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">
                          Health and Well-Being (including recreation and sport)
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Immigration"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">
                          Immigration (including immigrants, refugees and
                          migrant workers)
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Justice"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">
                          Justice
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Land"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">
                          Land / Infrastructure
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Reparations"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">
                          Reparations
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Social_Sustainability"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">
                          Social Sustainability &amp; Empowerment (including
                          child welfare, family services, housing){" "}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          name="Youth"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label pl-3">Youth</span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-sm-auto">
                      <hr
                        style={{
                          borderTop: "1px solid rgba(0,0,0,.9)",
                          paddingBottom: 40,
                        }}
                      />
                      <p className="text-left ml-3 mr-3">
                        As a member of the ANSDPAD Coalition you will receive
                        correspondence relating to the coalition and its
                        activities. To not be included please send an email to{" "}
                        <a href="#">admin@ansdpad.ca</a> with the word
                        UNSUBSCRIBE in the subject line.
                      </p>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 ml-3">
                      <button type="submit" className="btn mt-3">
                        <i className="fa fa-paper-plane"></i>&nbsp;Send message
                      </button>
                    </div>
                  </div>
                  <div
                    id="message"
                    style={{
                      display: "none",
                      margin: 20,
                      fontWeight: "bold",
                      color: "green",
                      padding: 8,
                      backgroundColor: "beige",
                      borderRadius: 4,
                      borderColor: "aquamarine",
                    }}
                  ></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Membership
