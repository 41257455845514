import React, { Component, Fragment } from "react"

interface Props {
  siteTitle: string;
}

class AboutBanner extends Component<{}> {
  constructor(props: Props) {
    super(props);

  }

  render() {
    return (
      <Fragment>
        <div id="slider" className="inspiro-slider dots-creative">
          <div className="slide kenburns" style={{  
            backgroundImage: "url(" + "https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/helping.png" + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}>
              <div className="bg-overlay"></div>
              <div className="shape-divider" data-style={1} data-height={300} style={{zIndex: 0, opacity: 1}}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 630 125.72" preserveAspectRatio="none" style={{height: '300px'}}>
                      <path d="M395,99.37c-57.43,10.17-124.27-8.06-176.8-11.72q3.93.64,8,1.41c50.81,6,113.3,24.08,168.76,14.26C468,90.42,519.6,11.88,630,8.9V0C505.42,4,468,86.47,395,99.37Z" style={{fill: '#fff', opacity: '0.30000000000000004'}} />
                      <path d="M226.25,89C273.88,98,338.14,119.29,395,109.23c72.93-12.91,126.61-77.46,235-73.48V8.86c-110.4,3-162,81.51-235,94.42C339.55,113.1,277.06,95.07,226.25,89Z" style={{fill: '#fff', opacity: '0.63'}} />
                      <path d="M60.82,122.88,62,123a31.49,31.49,0,0,0,9.34-.64A101.26,101.26,0,0,1,60.82,122.88Z" style={{fill: '#fff'}} /><path d="M60.82,122.88,62,123a31.49,31.49,0,0,0,9.34-.64A101.26,101.26,0,0,1,60.82,122.88Z" style={{fill: '#fff', opacity: '0.35000000000000003'}} />
                      <path d="M398,107.84c-56.15,10.07-119.59-11.26-166.62-20.2-2.71-.52-5.35-1-7.94-1.41A159.54,159.54,0,0,0,202,84q-3.09-.09-6,0c-.71,0-1.39.08-2.09.12-52.8,2.93-80.34,28.78-112.91,36.62a72.63,72.63,0,0,1-9.66,1.62A31.49,31.49,0,0,1,62,123l-1.18-.13C31.37,122.85,0,111.82,0,111.82v13.9H630V34.36C523,30.39,470,94.94,398,107.84Z" style={{fill: '#fff'}} />
                      <path d="M0,102.14v10c83,34,126.83-14,190-24l1-4c-44.4,6.26-45,22-93,31C54.78,123.25,30,113.14,0,102.14Z" style={{fill: '#fff', opacity: '0.30000000000000004'}} /><path d="M0,104.14v10c22,9,41.23,10.26,58.8,10,48.78-.76,84.76-26.65,131.2-34l1-4c-44.4,6.26-45,22-93,31C54.78,125.25,30,115.14,0,104.14Z" style={{fill: '#fff', opacity: '0.30000000000000004'}} />
                    </svg>
                </div>
                <div className="container-wide">
                    <div className="container-fullscreen">
                        <div className="text-middle">
                            <div className="heading-text text-light col-lg-6">
                                <h2 className="font-weight-800"><span style={{color: '#FCB616'}}>Membership</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AboutBanner
